import ExprealtyCaTheme from './exprealtyCA';
import ExprealtyUsTheme from './exprealtyUS';
import ZoocasaTheme from './zoocasa';
import ExpCommercialTenant from './expCommercial';
import ExpInServiceTheme from './expInService';

export type { ThemeConfig } from './themeConfig';

export const themes = {
  zoocasa: ZoocasaTheme,
  exprealtyCA: ExprealtyCaTheme,
  exprealtyUS: ExprealtyUsTheme,
  expInService: ExpInServiceTheme,
  expCommercial: ExpCommercialTenant,
};

export type ThemeName = keyof typeof themes;

import type { Filter } from 'contexts/preferences/listing-params/types';
import type { PartialDeep } from 'type-fest';
import { SEARCH_PAGE_ROUTE_NAME } from 'types/search_page_types';
import type { ProvinceOrStateCode } from 'utils/province_or_state';

export const PROVINCE_LOCATION_TYPE = 'province';
export const CITY_LOCATION_TYPE = 'city';
export const NEIGHBOURHOOD_LOCATION_TYPE = 'neighbourhood';
export const STREET_LOCATION_TYPE = 'street';
export const COUNTRY_LOCATION_TYPE = 'country';
export type LocationType = typeof PROVINCE_LOCATION_TYPE | typeof CITY_LOCATION_TYPE | typeof NEIGHBOURHOOD_LOCATION_TYPE | typeof STREET_LOCATION_TYPE | typeof COUNTRY_LOCATION_TYPE;

export const AREA_LISTINGS_ROUTE = 'area-listings';
export const LISTING_ROUTE = 'listing';
export const LOCATIONS_ROUTE = 'locations';
export const NAMED_CONTENT_ROUTE = 'named-content';
export const AGENTS_ROUTE = 'agents';
export const EMBER_ROUTE = 'ember';
export const NOT_FOUND_404_ROUTE = '404';

export type Route = typeof AREA_LISTINGS_ROUTE | typeof LISTING_ROUTE | typeof LOCATIONS_ROUTE | typeof NAMED_CONTENT_ROUTE | typeof AGENTS_ROUTE | typeof EMBER_ROUTE | typeof NOT_FOUND_404_ROUTE | typeof SEARCH_PAGE_ROUTE_NAME;
export type RouteName = Route;

export const SOLD_LISTINGS_LISTING_STATUS = 'sold-listings';
export const PAST_LISTINGS_LISTING_STATUS = 'past-listings';
export const EXPIRED_LISTINGS_LISTING_STATUS = 'expired-listings';
export const LEASED_LISTING_STATUS = 'leased';
export const SOLD_LISTING_STATUS = 'sold';
export type ListingStatus = typeof SOLD_LISTING_STATUS | typeof SOLD_LISTINGS_LISTING_STATUS | typeof PAST_LISTINGS_LISTING_STATUS | typeof EXPIRED_LISTINGS_LISTING_STATUS | typeof LEASED_LISTING_STATUS;

export const HOUSES_HOME_TYPE = 'houses';
export const TOWNHOUSES_HOME_TYPE = 'townhouses';
export const CONDOS_HOME_TYPE = 'condos';
export const LAND_HOME_TYPE = 'land';
export const COMMERCIAL_HOME_TYPE = 'commercial';
export const FARMS_HOME_TYPE = 'farms';
export const REAL_ESTATE_HOME_TYPE = 'real-estate';

export type RoutePropertyType = typeof HOUSES_HOME_TYPE | typeof TOWNHOUSES_HOME_TYPE | typeof CONDOS_HOME_TYPE | typeof LAND_HOME_TYPE | typeof COMMERCIAL_HOME_TYPE | typeof FARMS_HOME_TYPE | typeof REAL_ESTATE_HOME_TYPE;

type ListingInfoType = { listingId: string; listingSlug?: undefined } | { listingSlug: string; listingId?: undefined };

export type RouteMatchObject = {
  route: Route | undefined;
  locationType?: LocationType | null;
} & any;

export type ListingRouteMatchObject = { 
  route: typeof LISTING_ROUTE;
  locationType?: LocationType | null;
 } & ListingInfoType;

export type NamedContentRouteMatchObject = { 
  route: typeof NAMED_CONTENT_ROUTE;
  locationType?: LocationType | null;
 } & { url?: string }; 

export type AreaListingsRouteMatchObject = { 
    route: typeof AREA_LISTINGS_ROUTE;
    locationType?: LocationType | null;
    filter: PartialDeep<Filter>;
    listingStatus?: ListingStatus;
    city?: string;
    cityWithProvinceCode?: string;
    propertyType?: RoutePropertyType;
    url?: string;
    isRental?: string;
    province?: string;
    provinceCode?: ProvinceOrStateCode;
    neighbourhood?: string;
    street?: string;
    listingId?: string;
    listingSlug?: string;
    primaryFilter?: string;
    secondaryFilter?: string;
    country?: string;
  };
/**
 * Generates a canonical URL by removing all query parameters.
 * @param asPath - The full path from Next.js router (including query parameters).
 * @param baseHost - The base host of the application (e.g., https://www.zoocasa.com).
 * @returns The canonical URL without query parameters.
 */
export function generateCanonicalUrl(asPath = '', baseHost = ''): string {
  // Ensure the baseHost ends with a slash for consistency
  const formattedHost = baseHost.endsWith('/') ? baseHost.slice(0, -1) : baseHost;
  
  // Extract the base path (ignore anything after `?`)
  const [basePath] = asPath.split('?');
  
  // Construct and return the canonical URL
  return `${formattedHost}${basePath}`;
}
  
import { ENVIRONMENT_PRODUCTION, getEnvironment, isDevelopment, isEndToEndTest } from 'utils/environment';
import '../utils/monkeyPatchConsole';
import React, { useCallback, useEffect, useState } from 'react';
import { AppType } from 'next/dist/shared/lib/utils';
import App from 'next/app';
import Script from 'next/script';
import { GrowthBook, GrowthBookProvider } from '@growthbook/growthbook-react';
import configJSON from 'config.json';
import { useRouter } from 'next/router';
import HeadData from 'components/head-data';
import LocationPickerScreen from 'components/location-picker-screen';
import UserContextProvider from 'contexts/user';
import SavedListingContextProvider from 'contexts/saved-listing';
import ModalContextProvider from 'contexts/modal';
import ThemeContextProvider from 'contexts/theme';
import { trackAppLoadedEvent, trackGrowthbookEvent, trackWebVitals } from 'utils/google-tag-manager';
import PreferencesContextProvider, { LastSearchLocation } from 'contexts/preferences';
import { extractListingParamsData } from 'contexts/preferences/listing-params/utils';
import { extractSchoolParamsData } from 'contexts/preferences/school/utils';
import FeaturesContextProvider, { FeaturesType } from 'contexts/features';
import ChatContextProvider from 'contexts/chat';
import SnackbarContextProvider from 'contexts/snackbar';
import isUserAgentCrawler, { isUserAgentLighthouse } from 'utils/crawler-agent';
import 'styles/app.scss';
import { getFeaturesOverrideFromRequest } from 'utils/features';
import storage from 'utils/storage';
import AccessibilityWidget from 'components/accessibility-widget';
import { TrustArcCookieData, isTrustarcCookieTypeAllowed } from 'utils/trustarc-cookies';
import {
  GTM_SCRIPT,
  DEVICE_ID_COOKIE,
  getLastSearchLocationTag,
  SCREEN_COOKIE_NAME,
  USER_COOKIE_NAME,
  LOC_PICKER_COOKIE_NAME,
  CONNECTION_REQUEST_DISCLAIMERS_COOKIE_NAME,
  NOTICE_GDPR_PREFS_COOKIE_NAME,
  HUBSPOT_TRACKING_SCRIPT,
  DEFAULT_EXPIRE_DAYS,
  USER_LOCATION_COOKIE_NAME,
} from 'constants/cookies';
import getPopularCitiesCookie from 'utils/cookies/popular-cities-cookie';
import Cookies from 'js-cookie';
import { getObjectFromRequestCookie, getStringFromRequestCookie } from 'utils/cookies';
import ThemedFooter from 'components/themed-footer';
import { getGTMContainerIdFromTheme, getThemeOverrideFromRequest, themeNameOrDefault } from 'utils/themes';
import dynamic from 'next/dynamic';
import { ModalOpenersList } from 'types/modal';
import { ThemeNames } from 'types/themes';
import { buildClassName } from 'utils/build-class-name';
import { ni18nConfig } from '../../ni18n.config';
import { appWithI18Next } from 'ni18n';
import { SET_COOKIE_HEADER_NAME, USER_AGENT_HEADER_NAME, X_ZOOCASA_WEBVIEW_HEADER_NAME } from 'constants/headers';
import { getSiteLocationFromRequest } from 'utils/site-location';
import { ThemeName } from 'themes';
import { getUserLocationFromServerSideRequest, IUserLocation } from 'utils/user-location';
import MiddlewareManager from 'utils/fetchWithRetry';
import { useIsDesktop } from 'hooks/use-size-class';
import cookie from 'cookie';
import { CountryCodeList, type CountryCode } from 'types/countries';
import { createTenantHeaderMiddleware } from 'utils/tenant-injector-middleware';

import type { AppProps, AppContext, NextWebVitalsMetric, AppInitialProps } from 'next/app';
import type { SearchSuggestions } from 'components/suggested-location-dropdown';
import type { ListingParams } from 'contexts/preferences/listing-params/types';
import type { SchoolParams } from 'contexts/preferences/school/types';
import type { IUserContext, User } from 'contexts/user';
import type { Screen } from 'utils/types';

// A/B Testing Setup
const growthbook = new GrowthBook({
  apiHost: 'https://cdn.growthbook.io',
  clientKey: configJSON.growthbook.clientKey,
  enableDevMode: getEnvironment() !== ENVIRONMENT_PRODUCTION,
  subscribeToChanges: true,
  trackingCallback: (experiment, result) => {
    trackGrowthbookEvent(experiment.key, result.variationId);
  },
});

const parseFeatures = (themeName: ThemeName | null, features: FeaturesType ) => themeName === ThemeNames.EXP_REALTY_CA ? { ...features, useUsListings: false }: features;

ZoocasaNextApp.getInitialProps = async (context: AppContext): Promise<ZoocasaNextInitialProps> => {
  const { ctx: { req: request, res }} = context;

  const isCrawler = isUserAgentCrawler(request?.headers);
  const isCrawlerLighthouse = isUserAgentLighthouse(request?.headers?.[USER_AGENT_HEADER_NAME]);
  const isMobile = !!request?.headers?.[USER_AGENT_HEADER_NAME]?.toLowerCase().includes('mobile');

  const features = getFeaturesOverrideFromRequest(request) as unknown as FeaturesType;
  const themeOverride = getThemeOverrideFromRequest(request);
  const themeName = themeNameOrDefault(themeOverride) as ThemeNames;

  const userLocation = await getUserLocationFromServerSideRequest(request);
  if (userLocation) {
    res.setHeader(SET_COOKIE_HEADER_NAME, cookie.serialize(USER_LOCATION_COOKIE_NAME, JSON.stringify(userLocation), {
      httpOnly: false,
      secure: !isDevelopment,
      maxAge: DEFAULT_EXPIRE_DAYS,
    }));
  }
  const siteLocation = getSiteLocationFromRequest(request, userLocation.countryCode);

  const cookiePreference: string|undefined = getStringFromRequestCookie(NOTICE_GDPR_PREFS_COOKIE_NAME, request);
  const functionalCookiePreference: TrustArcCookieData = { cookiePreference, type: 'functional' };
  const advertisingCookiePreference: TrustArcCookieData = { cookiePreference, type: 'advertising' };

  const hasShownSitePickerScreen = getStringFromRequestCookie(LOC_PICKER_COOKIE_NAME, request) === 'true';
  const storedConnRequestDisclaimers = getStringFromRequestCookie(CONNECTION_REQUEST_DISCLAIMERS_COOKIE_NAME, request) === 'true';

  const userPopularCities = getPopularCitiesCookie(request, userLocation);
  const lastSearchLocation = getObjectFromRequestCookie<LastSearchLocation>(getLastSearchLocationTag(themeName), request);
  return {
    ...await App.getInitialProps(context),
    listingParamsData: extractListingParamsData(request),
    schoolParamsData: extractSchoolParamsData(request),
    screen: getObjectFromRequestCookie<Screen>(SCREEN_COOKIE_NAME, request),
    user: getObjectFromRequestCookie<User>(USER_COOKIE_NAME, request) || null,
    storedUserLocation: userLocation,
    storedSiteLocation: siteLocation,
    hasShownSitePickerScreen,
    storedConnRequestDisclaimers,
    features: parseFeatures(themeName, features),
    themeName: themeName,
    isCrawler,
    isCrawlerLighthouse,
    isWebView: !!request?.headers[X_ZOOCASA_WEBVIEW_HEADER_NAME],
    functionalCookiesEnabled: isTrustarcCookieTypeAllowed(functionalCookiePreference),
    advertisingCookiesEnabled: isTrustarcCookieTypeAllowed(advertisingCookiePreference),
    lastSearchLocationCookie: lastSearchLocation,
    storedUserPopularCities: userPopularCities,
    deviceId: request ? getStringFromRequestCookie(DEVICE_ID_COOKIE, request, Date.now().toString()) : Cookies.get(DEVICE_ID_COOKIE) || Date.now().toString(),
    originUrl: request?.headers.host || '',
    isMobile,
  };
};

type BaseProps = {
  listingParamsData: ListingParams;
  schoolParamsData: SchoolParams;
  screen?: Screen;
  user: IUserContext['user'];
  storedUserLocation?: IUserLocation;
  storedSiteLocation?: CountryCode;
  hasShownSitePickerScreen: boolean;
  storedConnRequestDisclaimers: boolean;
  features: FeaturesType;
  themeName?: ThemeNames;
  isCrawler: boolean;
  isCrawlerLighthouse: boolean;
  isWebView: boolean;
  functionalCookiesEnabled: boolean;
  advertisingCookiesEnabled: boolean;
  lastSearchLocationCookie?: LastSearchLocation;
  storedUserPopularCities?: SearchSuggestions[];
  deviceId?: string;
  originUrl: string;
  isMobile: boolean;
};

type ZoocasaNextInitialProps = AppInitialProps & BaseProps;
type ZoocasaNextProps = AppProps & BaseProps;

function sendToGA(metric: NextWebVitalsMetric) {
  trackWebVitals('web-vitals-' + metric.name, metric.value);
  if (process.env.TEST_ENVIRONMENT) {
    console.log(`${metric.name}: ${metric.value}`);
  }
}

function ZoocasaNextApp ({
  Component,
  pageProps,
  listingParamsData,
  schoolParamsData,
  screen,
  user,
  storedUserLocation,
  storedSiteLocation,
  hasShownSitePickerScreen,
  storedConnRequestDisclaimers,
  features,
  themeName,
  isCrawler,
  isCrawlerLighthouse,
  isWebView,
  functionalCookiesEnabled,
  advertisingCookiesEnabled,
  lastSearchLocationCookie,
  storedUserPopularCities,
  deviceId,
  originUrl,
  isMobile }: ZoocasaNextProps
) {
  //  Create a global middleware to automatically append the Tenant information into fetch requests to go-search
  const tenant = themeName;
  const tenantHeaderMiddleware = createTenantHeaderMiddleware(tenant);
  MiddlewareManager.setGlobalMiddleware(tenantHeaderMiddleware);
  const router = useRouter();

  const headDataIsActive = !(pageProps && 'routeName' in pageProps);
  const pageName = router.pathname.replace(/\//g, '-').substring(1) || 'home';
  const featuresWithOverrides = { ...configJSON.features, ...features };
  const gtmContainerId = getGTMContainerIdFromTheme(themeName);
  const [ModalDialog, setModalDialog] = useState<any>();
  const [showAccessibilityWidget, setShowAccessibilityWidget] = useState(true);
  const [Snackbar, setSnackbar] = useState<any>();
  const isExpTenant = [ThemeNames.EXP_REALTY_CA, ThemeNames.EXP_REALTY_US].includes(themeName);
  const isDesktop = useIsDesktop();

  const showLocationPickerScreen = (storedUserLocation.countryCode === CountryCodeList.UNITED_STATES && !hasShownSitePickerScreen);

  const handleGrowthbookSetup = useCallback(() => {
    if (!isEndToEndTest) {
      const uniqId = Date.now().toString();
      const id = deviceId || uniqId;
      growthbook.setAttributes({ id, anonymous_id: id, loggedIn: user?.id });
      growthbook.init();
      if (!deviceId) {
        Cookies.set(DEVICE_ID_COOKIE, id);
      }
      else if (!Cookies.get(DEVICE_ID_COOKIE)) {
        Cookies.set(DEVICE_ID_COOKIE, deviceId);
      }
    }
  }, [user, deviceId]);

  useEffect(() => {
    handleGrowthbookSetup();
  }, [handleGrowthbookSetup]);

  const lazyComponentsHandler = () => {
    if (!ModalDialog) {
      setModalDialog(dynamic(import('components/modal-dialog')));
    }
    if (!Snackbar) {
      setSnackbar(dynamic(import('components/snackbar')));
    }
  };

  useEffect(() => {
    window.addEventListener('click', lazyComponentsHandler, { passive: true, once: true });
    const doesQueryTriggerModal = !!Object.keys(router.query).filter(q => ModalOpenersList.includes(q as any))?.length;
    if (!ModalDialog && doesQueryTriggerModal) {
      setModalDialog(dynamic(import('components/modal-dialog')));
    }

    const hideAccessibilityWidget = storage.get('accessibilityWidgetClosed');
    // Hide only if value is true
    if (hideAccessibilityWidget) setShowAccessibilityWidget(false);

    return () => window.removeEventListener('click', lazyComponentsHandler);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!functionalCookiesEnabled) return;
    const timer = setTimeout(() => {
      trackAppLoadedEvent();
    }, 2000);
    return () => clearTimeout(timer);
  }, [gtmContainerId, functionalCookiesEnabled]);

  return (
    <>
      {functionalCookiesEnabled &&
        <>
          <Script
            id={GTM_SCRIPT}
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmContainerId}');`,
            }}
          />
          {isExpTenant && <Script 
            id={HUBSPOT_TRACKING_SCRIPT}
            strategy="afterInteractive"
            src="//js.hs-scripts.com/24210079.js"
          />}
        </>
      }
      <FeaturesContextProvider features={features} isWebView={isWebView} isMobile={isMobile}>
        <ThemeContextProvider themeName={themeName} originUrl={originUrl}>
          {headDataIsActive && <HeadData />}
          <ChatContextProvider>
            <ModalContextProvider isCrawler={isCrawler}>
              <SnackbarContextProvider>
                <UserContextProvider user={user} userLocation={storedUserLocation} storedSiteLocation={storedSiteLocation} storedConnRequestDisclaimers={storedConnRequestDisclaimers} storedUserPopularCities={storedUserPopularCities} isCrawler={isCrawler}>
                  <PreferencesContextProvider listingParamsData={listingParamsData} schoolParamsData={schoolParamsData} screen={screen} functionalCookiesEnabled={functionalCookiesEnabled} advertisingCookiesEnabled={advertisingCookiesEnabled} lastSearchLocationCookie={lastSearchLocationCookie}>
                    <SavedListingContextProvider>
                      <GrowthBookProvider growthbook={growthbook}>
                        <div className={buildClassName(`body-container ${pageName}`, themeName !== ThemeNames.ZOOCASA && 'exp-theme' )}>
                          {isExpTenant && showAccessibilityWidget && isDesktop && (
                            <div className='accessibility-widget-wrapper'>
                              <AccessibilityWidget />
                            </div>
                          )}
                          <Component {...pageProps} />
                          <ThemedFooter />
                          {ModalDialog && <ModalDialog />}
                          {!isCrawler && !isCrawlerLighthouse && showLocationPickerScreen && featuresWithOverrides.useUsListings && <LocationPickerScreen />}
                          {isMobile && Snackbar && <Snackbar />}
                        </div>
                      </GrowthBookProvider>
                    </SavedListingContextProvider>
                  </PreferencesContextProvider>
                </UserContextProvider>
              </SnackbarContextProvider>
            </ModalContextProvider>
          </ChatContextProvider>
        </ThemeContextProvider>
      </FeaturesContextProvider>
    </>
  );
}

export function reportWebVitals(metric: NextWebVitalsMetric) {
  const messageMappings: Record<string, string> = {
    FCP: 'FCP: First Contentful Pain:',
    LCP: 'LCP: Largest Contentful Paint:',
    FID: 'FID: First Input Delay:',
    TTFB: 'TTFB: Time To First Byte:',
    CLS: 'CLS: Cumulative Layout Shift:',
    'Next.js-hydration': 'Next.js Hydration:',
    'Next.js-route-change-to-render': 'Next.js Route Change to Render:',
    'Next.js-render': 'Next.js Render:',
  };

  sendToGA(metric);

  const metricMessage: string | undefined = messageMappings[metric.name];

  if (metricMessage) {
    console.log(metricMessage, metric.value);
  } else {
    console.log('Unrecognized Metric:', metric);
  }
}

const ZoocasaWithTranslation = appWithI18Next(ZoocasaNextApp as any, ni18nConfig);
(ZoocasaWithTranslation as AppType).getInitialProps = async (appContext: AppContext) => {
  // The appWithI18Next HOC might be interfering with the getInitialProps
  const appProps = await ZoocasaNextApp.getInitialProps(appContext);
  return {
    ...appProps,
  };
};

export default ZoocasaWithTranslation;

export enum ThemeNames {
  ZOOCASA = 'zoocasa',
  EXP_REALTY_CA = 'exprealtyCA',
  EXP_REALTY_US = 'exprealtyUS',
  EXP_IN_SERVICE = 'expInService',
  EXP_COMMERCIAL = 'expCommercial'
}

export type Themes =
  ThemeNames.ZOOCASA |
  ThemeNames.EXP_REALTY_CA |
  ThemeNames.EXP_REALTY_US |
  ThemeNames.EXP_IN_SERVICE |
  ThemeNames.EXP_COMMERCIAL;
